import { useStaticQuery, graphql } from "gatsby"
import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { v4 as uuidv4 } from 'uuid';


import Nav from "./nav"
import SocialNetworks from "./social-networks"
import Swipe from "./swipe"

const HeaderWrapper = styled.header`
  height: calc(100vh - 50px);
  width: 100%;
  @media screen and (min-width: 900px) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
`

// eslint-disable-next-line react/jsx-props-no-spreading
const ProfileImage = styled(props => <Img {...props} />)`
  width: auto;
  max-width: 600px;
  margin: auto;
  z-index: -1;
  @media screen and (min-width: 900px) {
    width: 50%;
    margin: initial;
  }
`

const CharacterWrapper = styled.div`
  display: block;
  margin: auto;
  max-width: 250px;
  margin-top: -3rem;

  @media screen and (min-width: 900px) {
    margin-top: auto;
    margin: initial;
    max-width: 500px;
    width: 50%;
    margin-inline-end: 10rem;
  }
`

const Word = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  span {
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 0.095em;
    font-size: calc(16px + 6 * ((100vw - 320px) / 680));
  }

  @media screen and (min-width: 900px) {
    span {
      font-size: calc(16px + 10 * ((100vw - 320px) / 680));
    }
  }
`

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      profileImage: file(relativePath: { eq: "samuelfialho.png" }) {
        childImageSharp {
          fluid(maxWidth: 1000, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  if (!data?.profileImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  return <ProfileImage fluid={data.profileImage.childImageSharp.fluid} />
}

const Header = () => {
  const tagline = "frontend developer thinking about systems"
  const words = tagline.split(" ")

  const styledTagline = words.map(word => (
    <Word key={uuidv4()}>
      {word.split("").map(character => (
        <span key={uuidv4()}>{character}</span>
      ))}
    </Word>
  ))

  return (
    <>
      <Nav />
      <HeaderWrapper>
        <Image alt="samuel fialho profile" />
        <CharacterWrapper>{styledTagline}</CharacterWrapper>
      </HeaderWrapper>
      <SocialNetworks />
      <Swipe />
    </>
  )
}

export default Header
