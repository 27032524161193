import React from "react"

import Header from "./header"
import About from "./about"
import Bio from "./bio"

const Layout = () => {
  return (
    <>
      <Header />
      <About />
      <Bio />
    </>
  )
}

export default Layout
