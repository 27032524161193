import React from "react"
import styled from "styled-components"

import { grey } from "../styles/colorSchema"

const Wrapper = styled.div`
    width: 70px;
    position: fixed;
    bottom: 1.9rem;
    right: .6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: rotate(90deg);
    color: ${grey};
    &::before {
      content: "";
      width: 90px;
      height: 1px;
      transform: translate(-80px, 10px);
      background-color: ${grey};
      display: inline-block;
    }
`


const Swipe = () => (
  <Wrapper>
    <small>Swipe</small>
  </Wrapper>
)

export default Swipe;