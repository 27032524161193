import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
// import { white } from "../styles/colorSchema"

import Logo from "../images/logo.svg"

const NavWrapper = styled.div`
  height: 50px;
  display: flex;
  justify-content: space-between;
  padding: .6rem 1.2rem 0;

  a {
    display: block;
    height: 100%;
  }

  svg {
    display: block;
  }
`

// const NavMenu = styled.nav`
//   ul {
//     list-style: none;
//     margin: 0;
//     padding: 0;
//   }

//   li {
//     text-transform: uppercase;
//     text-align: right;
//     line-height: 1rem;
//   }

//   @media screen and (min-width: 400px) {
//     ul {
//       display: flex;
//     }

//     li {
//       padding: 0 .5rem;
//     }
//   }
// `

// eslint-disable-next-line react/jsx-props-no-spreading
// const NavMenuItem = styled(props => <Link {...props} />)`
//   text-decoration: none;
//   color: ${white};
//   font-weight: 600;
//   font-size: 0.75rem;
//   letter-spacing: 0.1rem;
// `

export default function Nav() {
  return (
    <NavWrapper>
      <div>
        <Link to="/">
          <Logo />
        </Link>
      </div>
      {/* <NavMenu aria-labelledby="mainmenulabel">
        <ul>
          <li>
            <NavMenuItem to="/">home</NavMenuItem>
          </li>
          <li>
            <NavMenuItem to="/work">work</NavMenuItem>
          </li>
          <li>
            <NavMenuItem to="/contact">contact</NavMenuItem>
          </li>
        </ul>
      </NavMenu> */}
    </NavWrapper>
  )
}
