import React from "react"
import styled from "styled-components"

import { grey, white } from "../styles/colorSchema"

const Section = styled.section`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 5rem;
    box-sizing:border-box;
    margin-bottom: 4rem;

    @media screen and (min-width: 900px) {
        flex-direction: row;
        justify-content: center;
        padding: 10rem;
    }
`

const SubSection = styled.div`
    width: 100%;
    font-size: 14px;

    ul {
        padding-inline-start: 1rem;
        margin-block-start: .2rem;
    }

    li {
        margin: 0 0 1rem 0;
    }

    h2 {
        max-width: 400px;
        color: ${grey};
        font-weight: bold;
        font-size: 30px;
        line-height: 106.3%;
        letter-spacing: -0.05em;
    }

    @media screen and (min-width: 900px) {
        max-width: 500px;
        margin: 2rem;

        h2 {
            font-size: 50px;
            line-height: 106.3%;
            letter-spacing: -0.05em;
        }
    }
`

const Job = styled.span`
    display: block;
    font-weight: 600;
    color: ${white};
`

const Bio = () => (
  <Section>
    <SubSection>
      <h2>Experience</h2>
      <ul>
        <li>
          January 2019 — Present
          <Job>Front-end Developer at Nuxeo</Job>
        </li>
        <li>
          January 2016 — December 2018
          <Job>Freelancing as designer and front-end developer</Job>
        </li>
        <li>
          May 2013 — December 2015
          <Job>Digital Impulse Co-founder</Job>
        </li>
        <li>
          October 2009 — April 2013
          <Job>Freelancing as a front-end developer</Job>
        </li>
      </ul>
    </SubSection>
    <SubSection>
      <h2>Education</h2>
      <ul>
        <li>
          September 2019 — June 2020
          <Job>Post-Graduation in Digital Experience Design</Job>
          Faculdade de Belas-Artes, Universidade de Lisboa
        </li>
        <li>
          September 2018 — December 2018
          <Job>React Nano Degree</Job>
          Udacity
        </li>
        <li>
          January 2016
          <Job>Social Media Marketing Strategy</Job>
          EDIT. - Disruptive Digital Education
        </li>
        <li>
          September 2004 — September 2009
          <Job>Graduation in Journalism</Job>
          Escola Superior de Comunicação Social, IPL
        </li>
      </ul>
    </SubSection>
  </Section>
)

export default Bio;