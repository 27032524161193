import React from "react"
import styled from "styled-components"

import { grey, white } from "../styles/colorSchema"

const Section = styled.section`
    width: 100%;
    height: 100vh;
    background-color: ${white};
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2, p {
        color: ${grey};
        padding: 0 5.4rem;
        max-width: 500px;
    }

    h2 {
        font-weight: bold;
        font-size: 30px;
        line-height: 106.3%;
        letter-spacing: -0.05em;
    }

    @media screen and (min-width: 900px) {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        
        h2, p {
            padding: 0 2rem;
            margin: 0;
        }

        h2 {
            font-size: 50px;
            line-height: 106.3%;
            letter-spacing: -0.05em;
            max-width: 385px;
        }

        p {
            max-width: 315px;
        }
    }
`

const About = () => (
  <Section>
    <h2>I’m a front-end developer with more than a decade of professional experience.</h2>
    <p>
      Currently, I’m working at
      <a href="https://nuxeo.com"> Nuxeo</a>
      , an open-source Enterprise Content Management System. Inside my team, I’m responsible for maintaining and developing our product’s user interface, with a special focus on creating and implementing our Design System.
    </p>
  </Section>
)

export default About;