import React from "react"
import styled from "styled-components"

import Github from "../images/github.svg"
import Linkedin from "../images/linkedin.svg"
import Instagram from "../images/instagram.svg"

const Wrapper = styled.div`
    width: 70px;
    position: fixed;
    bottom: .6rem;
    left: .6rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    > * {
        margin: .25rem 0;
    }

    svg {
      width: 20px;
      height: 20px;
    }

    a:hover {
      border: 0 none transparent;
      padding: 0;
    }
`


const SocialNetworks = () => (
  <Wrapper>
    <a href="https://github.com/semisse" rel="noreferrer" target="_blank">
      <Github />
    </a>
    <a href="https://www.linkedin.com/in/samuelfialho/" rel="noreferrer" target="_blank">
      <Linkedin />
    </a>
    <a href="https://instagram.com/semisse" rel="noreferrer" target="_blank">
      <Instagram />
    </a>
  </Wrapper>
)

export default SocialNetworks;